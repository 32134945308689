import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {i18n} from './locales/config'

import DefaultLayout from './layouts/DefaultLayout.vue'
import AuthLayout from './layouts/AuthLayout.vue'
import EmptyLayout from './layouts/EmptyLayout.vue'
import StreamLayout from './layouts/StreamLayout.vue'
import ClearLayout from './layouts/ClearLayout.vue'

import VueYouTubeEmbed from 'vue-youtube-embed'
import vueVimeoPlayer from 'vue-vimeo-player'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import ToggleButton from 'vue-js-toggle-button'
import VueSweetalert2 from 'vue-sweetalert2'
import Vuelidate from 'vuelidate'
import VueKonva from 'vue-konva'
import VCalendar from 'v-calendar';

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.use(VueYouTubeEmbed)

Vue.use(vueVimeoPlayer)

Vue.component('default-layout', DefaultLayout)
Vue.component('auth-layout', AuthLayout)
Vue.component('empty-layout', EmptyLayout)
Vue.component('stream-layout', StreamLayout)
Vue.component('clear-layout', ClearLayout)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(ToggleButton)

Vue.use(VueSweetalert2);

Vue.use(Vuelidate)

Vue.use(VueKonva)

Vue.use(VCalendar, {
  componentPrefix: 'vc'
});

import VueCryptojs from 'vue-cryptojs'
Vue.use(VueCryptojs)

import VueCountdown from '@chenfengyuan/vue-countdown';

Vue.component(VueCountdown.name, VueCountdown);

import moment from 'moment';
Vue.prototype.$moment = moment;

Vue.config.productionTip = false

Object.defineProperty(Vue.prototype,"$bus",{
  get: function() {
    return this.$root.bus;
  }
});

new Vue({
  router,
  store,
  i18n,
  data: {
    bus: new Vue({})
  },
  render: h => h(App)
}).$mount('#app')
