<template>
  <div class="container" v-if="isLoggedIn">
    <div class="navigation-options" v-if="!loading">
      <router-link to="/orders" class=" navbar-brand navigation-bar__options__item orders align-items-center" href="#">
        <span class="navigation-bar__options-icon">
          <img class="orders" :src="ordersSVG" alt="Заказы">
        </span>
        <p>
          <span class="navigation-bar__options-name mb-0">{{$t('menu.orders')}}</span>
<!--          <span class="navigation-bar__options-description">Lorem ipsum dolor sit amet.</span>-->
        </p>
      </router-link>
      <router-link to="/affiliate" class="navbar-brand navigation-bar__options__item affiliate align-items-center disabled" href="#">
        <span class="navigation-bar__options-icon">
          <img class="affiliate" :src="affiliateSVG" alt="Партнерство">
        </span>
        <p>
          <span class="navigation-bar__options-name mb-0">{{$t('menu.affilate')}}</span>
        </p>
      </router-link>
      <router-link  to="/user-profile" class="navbar-brand navigation-bar__options__item affiliate align-items-center">
        <span class="navigation-bar__options-icon">
          <img class="affiliate" :src="profileSVG" alt="Профиль">
        </span>
        <p><span class="navigation-bar__options-name mb-0">{{$t('menu.profile')}}</span></p>
      </router-link>
    </div>
  </div>
</template>

<script>
import profileSVG from '@/assets/img/profile.svg'
import affiliateSVG from '@/assets/img/affiliate.svg'
import ordersSVG from '@/assets/img/orders.svg'

export default {
  name: "NavOptions",
  data() {
    return {
      profileSVG,affiliateSVG,ordersSVG,
      loading: false,
      order: null
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
  }
}
</script>

<style lang="scss" scoped>
.navigation-options {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
  padding-top: 20px;
}

.navigation-bar__options__item {
  width: 32%;
  margin: 0;
  padding: 25px;
  border-radius: 5px;
  color: #000;
  font-size: 24px;
  background-color: #fff;
  box-shadow: 0 4px 20px 0 rgba(0,0,0,.04);
  border: 2px solid transparent;
  display: flex;
  transition: all .2s ease;
  will-change: transform;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  p {
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-left: 15px;
  }
  &:not(.disabled):hover {
    border: 2px solid #533BAA;
    transform: translateY(-5px)
  }
}
.navigation-bar__options-name {
  display: inline-block;
  font-size: 24px;
  line-height: 1;
  margin-bottom: 10px;
}
.navigation-bar__options-description {
  font-size: 13px;
  line-height: 1.1;
  color: #aaa;
  white-space: normal;
}
@media screen and (max-width: 991px) {
  .navigation-bar__options__item {
    padding: 10px;
    align-items: center;
    p {
      margin-left: 10px;
    }
  }
  .navigation-bar__options-name {
    font-size: 18px;
    margin-bottom: 5px
  }
}
@media screen and (max-width: 768px) {
  .navigation-bar__options__item {
    flex-direction: column;
    p {
      text-align: center;
      margin-top: 10px;
      margin-left: 0;
    }
  }
  .navigation-bar__options-name {
    font-size: 18px;
    margin-bottom: 5px
  }
}

@media screen and (max-width: 550px) {
  .navigation-options {
    flex-direction: column;
    .navigation-bar__options__item {
      width: 100%;
      margin-bottom: 15px;
      flex-direction: row;
      text-align: left;
      justify-content: flex-start;
      p {
        text-align: left;
        margin-top: 0;
      }
    }
    .navigation-bar__options-icon {
      margin-right: 15px;
      .orders {
        max-width: 43px;
      }
      .affiliate {
        max-width: 37px;
      }
      .profile {
        max-width: 40px;
      }
    }
  }
}
</style>