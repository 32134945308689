<template>
  <div>
    <NavBar />
    <main class="main" v-if="!loading">
      <NavOptions v-if="!hideMenu" />
      <slot />
    </main>
  </div>
</template>

<script>
import NavBar from "@/components/general/NavBar";
import NavOptions from "@/components/general/NavOptions";
import {showProfile} from "@/services/customer/profile/profile";

export default {
  name: 'DefaultLayout',
  components: {
    NavBar,
    NavOptions
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    hideMenu() {
      return this.$route.meta.hideMenu
    },
    token() {
      return this.$store.getters.isLoggedIn
    },
  },
  beforeMount () {
    this.$i18n.locale = this.$store.state.curLanguage.locale;
  },
  created() {
    if (this.token) {
      this.loading = true
      this.loadUserData()
    }
    this.$bus.$on('fetchUserData', this.loadUserData)
  },
  methods: {
    loadUserData() {
      showProfile().then(response => {
        this.$store.dispatch('login', response)
      }).finally(() => {this.loading = false}).catch(error => {
        this.$swal(this.$t('error.error'), error.message, 'error')
      })
    }
  }
}
</script>

<style lang="scss">
.main {
  position: relative;
  //&:after {
  //  content: '';
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  background: #f5f5f5;
  //  width: 100%;
  //  height: 400px;
  //  z-index: -1;
  //}
}
</style>