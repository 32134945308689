import Vue from 'vue'
import Vuex from 'vuex'
import {showProfile} from "@/services/customer/profile/profile";

function getBaseUrl() {
  // let url =  'https://a4.rte.im'
  // if (window.location.origin.includes('c3.rte.im')
  //     || window.location.origin.includes('localhost')
  //     || window.location.origin.includes('192.168.31.214')
  //     || window.location.origin.includes('192.168.1.107')
  // ) {
  //   url = 'https://a3.rte.im'
  // }
  return 'https://a4.rte.im'
}

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    globalLoading: false,
    token: localStorage.getItem('customerToken') || '',
    email: localStorage.getItem('customerEmail') || '',
    userData: {},
    curLanguage: {
      locale: localStorage.getItem('curLanguageLocale') == null ? 'en_us' : localStorage.getItem('curLanguageLocale'),
    },
    layout: 'default-layout',
    credentials: {
      headers: {
        'Accept-Language': getHeaderLanguage(localStorage.getItem('curLanguageLocale') === null ? 'ru' : localStorage.getItem('curLanguageLocale'))
      }
    },
    url: getBaseUrl(),
    nonCashPayment: {
      fioCompany: '',
      edrpou: '',
      bankAccount: '',
      addressPhone: ''
    },
    interval: null
  },
  mutations: {
    setLanguage(state, data) {
      state.curLanguage.locale = data.lang;
      state.credentials.headers['Accept-Language'] = getHeaderLanguage(data.lang)
      localStorage.setItem('curLanguageLocale', state.curLanguage.locale);
    },
    setUserData(state, data) {
      if (data.token) {
        localStorage.setItem('customerToken', data.token)
      }
      localStorage.setItem('customerEmail', data.email)

      Object.assign(state, {
        token: data.token ? data.token : localStorage.getItem('customerToken'),
        email: data.email,
        userData: data
      })
	
			state.interval = setInterval(() => {
				showProfile().then(() => {}).catch(() => {})
			},  30000)
    },
    resetUserData: state => {
      clearInterval(state.interval)
      Object.assign(state, { token: '', email: '' })
      localStorage.removeItem('customerToken')
      localStorage.removeItem('customerEmail')
    },
    setLayout(state, payload) {
      state.layout = payload
    },
    setNonCashPaymentInfo: (state, payload) => {
      state.nonCashPayment = payload
    },
    setGlobalLoading: (state, payload) => {
      state.globalLoading = payload
    },
		setToken: (state, payload) => {
			state.token = payload
			localStorage.setItem('customerToken', payload)
		}
  },
  actions: {
    login: (context, data) => {
      context.commit('setUserData', data)
    },
    logout: (context) => {
      context.commit('resetUserData', '')
    },
    languageChange: (context, data) => {
      context.commit('setLanguage', data)
    },
    changeNonCashInfo: (context, data) => {
      context.commit('setNonCashPaymentInfo', data)
    },
    setGlobalLoading: (context, data) => {
      context.commit('setGlobalLoading', data)
    },
		setToken: (context, data) => {
			context.commit('setToken', data)
		}
  },
  getters: {
    isLoggedIn: state => {
      return state.token
    },
    getUserEmail: state => {
      return state.email
    },
    getUserData: state => {
      return state.userData
    },
    curLanguage(state) {
      return state.curLanguage
    },
    layout(state) {
      return state.layout
    },
    nonCashPaymentInfo(state) {
      return state.nonCashPayment
    },
    url(state) {
      return state.url
    },
    globalLoading(state) {
      return state.globalLoading
    }
  }
})

function getHeaderLanguage(lang) {
  if(lang === 'ru_ru') {
    return 'ru'
  } else if(lang === 'en_us') {
    return 'en'
  } else if(lang === 'uk_ua') {
    return 'ua'
  }
  return 'ru'
}