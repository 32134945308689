<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
export default {
  beforeMount () {
    this.$i18n.locale = this.$store.state.curLanguage.locale;
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'auth-layout'
    }
  }
}
</script>

<style lang="scss">
html {
  min-height: 100%;
}
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  //background-color: #cfd3d7 !important;
  background-color: #f2f3f8 !important; //fafafa
  font-family: 'Montserrat', sans-serif !important;
  font-size: 15px;
  font-weight: 400;
  color: #343a40;
}
#app {
  //height: 100%;
  position: relative;
}
main {
  width: 100%;
  position: relative;
}
.modal-backdrop {
  opacity: 0.3;
}
.swal2-icon.swal2-warning::before {
  content: ''
}
@media (min-width:768px) and (max-width: 1024px) {
  .container {
    max-width: 960px !important;
  }
}
@media (min-width:576px) and (max-width: 768px) {
  .container {
    max-width: 100% !important;
  }
}
@media (min-width:768px) and (max-width: 992px) {
  .container {
    max-width: 100% !important;
  }
}
</style>