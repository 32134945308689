import axios from 'axios'
// let qs = require('qs');
import errorResponseHandler from "@/api/responseError/errorResponseHandler";

const backendApi = getBaseUrl()

function getBaseUrl() {
    // let url =  'https://a4.rte.im'
    // if (window.location.origin.includes('c3.rte.im') || window.location.origin.includes('localhost')) {
    //     url = 'https://a3.rte.im'
    // }
    return 'https://a4.rte.im'
}

export function getProfileInfo (headers) {
    return new Promise((resolve, reject) => {
        axios.get(`${backendApi}/api/v1/showProfile/`, headers)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(errorResponseHandler(error.response))
            })
    })
}

export function updateProfileInfo (meta, headers) {
    return new Promise((resolve, reject) => {
        axios.put(`${backendApi}/api/v1/updateProfile/`, meta, headers)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(errorResponseHandler(error))
            })
    })
}