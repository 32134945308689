import axios from 'axios'
import store from '../../../store/'

const backendApi = store.getters.url

export function authUser(data) {
  const meta = {
    email: data.email,
    password: data.password
  }
  if (data.role_id) {
      meta.role_id = data.role_id
  }
  return new Promise((resolve, reject) => {
    axios
      .put(`${backendApi}/api/v1/auth`, meta, store.state.credentials)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function forgotPass(email, isStaff) {
  const meta = {email, is_staff: false}
  if (isStaff) meta.is_staff = true
  return new Promise((resolve, reject) => {
    axios
        .post(`${backendApi}/api/v1/forgotPassword`, meta, store.state.credentials)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error.response)
        })
  })
}

export function verifyResetPasswordToken(token) {
    const meta = {
        params: {
            reset_token: token
        }
    }
    return new Promise((resolve, reject) => {
        axios
            .get(`${backendApi}/api/v1/verifyResetPasswordToken`, meta, store.state.credentials)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function resetPassword(token, password) {
    const meta = {
        reset_token: token,
        password: password
    }
    return new Promise((resolve, reject) => {
        axios
            .post(`${backendApi}/api/v1/resetPassword`, meta, store.state.credentials)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}
