<template>
  <div class="empty-wrapper stream-layout">
    <NavBar :isStream="true" short="true"/>
    <main class="main">
      <div class="stream__menu">
        <div class="container">
          <ul class="stream__menu-list">
            <li class="stream__menu-item" v-for="tabData in tabs.filter(tab => !tab.hidden)" v-bind:key="tabData.value">
              <router-link  href="#"
                            :to="'/event/' + eventId + '/order/' + orderId + '/ticket/' + ticketId + '/' + tabData.value"
                            class="stream__menu-link"
                            :class="{'active': activetab === tabData.value}">
                {{$t(tabData.name)}}
              </router-link>
            </li>
            <li class="stream__menu-coins ml-auto">
              <span v-if="user">{{user.coins}} coins</span>
            </li>
          </ul>
          <div class="stream__menu--mobile">
            <b-dropdown>
              <template #button-content>
                {{$t('stream.menu')}}
              </template>
              <template v-for="(tabData,i) in tabs.filter(tab => !tab.hidden)">
                <b-dropdown-item v-bind:key="tabData.value">
                  <router-link  href="#"
                                :to="'/event/' + eventId + '/order/' + orderId + '/ticket/' + ticketId + '/' + tabData.value"
                                class="stream__menu-link d-block w-100"
                                :class="{'active': activetab === tabData.value}">
                    {{$t(tabData.name)}}
                  </router-link>
                </b-dropdown-item>
                <b-dropdown-divider v-bind:key="tabData.value + '_' + i"></b-dropdown-divider>
              </template>
            </b-dropdown>
            <div class="stream__menu-coins">
              <span v-if="user">{{user.coins}} coins</span>
            </div>
          </div>
        </div>
      </div>
      <div class="stream__container" v-if="!loading">
        <slot @refreshData="getUserData" @isExtended="handleExtendedMode" />
      </div>
    </main>
  </div>
</template>

<script>
import NavBar from "@/components/general/NavBar";
import {showProfile} from "@/services/customer/profile/profile";

export default {
  name: 'StreamLayout',
  components: {
    NavBar
  },
  data() {
    return {
      loading: false,
      user: null,
      currentTicket: null,
      activetab: 'streams',
      tabs: [
        {
          name: 'stream.stream',
          value: 'stream',
          hidden: false
        },
        {
          name: 'stream.stands',
          value: 'stands',
          hidden: true
        },
        {
          name: 'stream.shop',
          value: 'shop',
          hidden: true
        },
      ],
    }
  },
  computed: {
    eventId() {
      return this.$route.params.eventId
    },
    orderId() {
      return this.$route.params.orderId
    },
    ticketId() {
      return this.$route.params.ticketId
    }
  },
  beforeMount () {
    this.$i18n.locale = this.$store.state.curLanguage.locale;
  },
  created() {
    this.$bus.$on('refreshData', this.getUserData)
    this.$bus.$on('isExtended', this.handleExtendedMode)
    this.loading = true
    this.getUserData()
  },
  methods: {
    getUserData() {
      showProfile().then(response => {
        this.user = response
      }).finally(() => {
        this.loading = false
      }).catch(error => {
        this.$swal(this.$t('error.error'), error.data.message, 'error')
      })
    },
    handleExtendedMode(data) {
      if (data === 0) {
        this.tabs[1].hidden = this.tabs[2].hidden = true
      } else if (data === 1) {
        this.tabs[1].hidden = this.tabs[2].hidden = false
      }
    },
  }
}
</script>

<style lang="sass">
body
  height: 100%
  background: #fff
.empty-wrapper
  height: 100%

.stream__menu
  background: #fff
.stream__menu-list
  padding: 0
  margin: 0
  height: 64px
  display: flex
  align-items: center
  list-style: none
.stream__menu-item
  margin-right: 20px
.stream__menu-link
  padding: 0
  font-size: 16px
  color: #555
  &:hover
    color: #533baa
  &.active
    color: #533baa
.stream__menu--mobile
  display: none
  .dropdown
    height: 64px
    flex-grow: 1
    .dropdown-toggle
      text-align: left
      border: 0
      color: #555 !important
      font-size: 14px
      background: #fff
      &:hover, &:focus, &:active
        background: transparent
      &::after
        content: ''
        display: inline-block
        font-size: 14px
        border-top: .3em solid
        border-right: .3em solid transparent
        border-bottom: 0
        border-left: .3em solid transparent
    .dropdown-menu
      right: 0
      margin: 0px -10px
      padding: 1rem 0
      font-size: 14px
      border-radius: 0
      li:last-child
        display: none
      .b-dropdown-text
        padding: 0 1.2rem

@media screen and (max-width: 768px)
  .stream__menu-link,
  .stream__menu-coins span
    font-size: 14px
  .stream__menu-list
    display: none
  .stream__menu--mobile
    display: flex
    align-items: center
    justify-content: flex-start
</style>