<template>
  <div class="clear-page">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ClearLayout"
};
</script>

<style scoped>

</style>