<template>
  <div class="navbar" :style="{height: isLoggedIn && !short ? '95px' : '55px'}">
    <div class="navbar__bot" :class="{ 'active' : displayBottom }" :style="`background:${navColor.value};color:${navTextColor.value}`">
      <div class="container">
<!--      <div class="navbar__bot__element email">-->
<!--        <div v-if="isLoggedIn">-->
<!--          {{ getUserEmail }}-->
<!--          <i class="fa fa-user-circle-o"/>-->
<!--        </div>-->
<!--      </div>-->

      <div  class="navbar__bot__element cabinet p-0" :class="{'stream': isStream}">
        <span class="navbar__info-text stream-email text-truncate text-left pl-0 ml-0" v-if="isStream">
          <router-link to="/orders">
            <i class="fa fa-user-o mr-1"/>
            {{ getUserEmail }}
          </router-link>
        </span>
<!--        <button v-if="isLoggedIn" class="btn" v-on:click="handleLogout">-->
<!--          Выйти-->
<!--          <i class="fa fa-sign-out"/>-->
<!--        </button>-->
        <router-link to="/login" v-if="!isLoggedIn && $route.path !== '/login'">
          <span class="cabinet-text">
            {{$t('menu.cabinet')}}
          </span>
          <i class="fa fa-sign-out"/>
        </router-link>
      </div>

      <div class="navbar__bot__element">
        <router-link to="/orders">
          <template v-if="$route.path === '/add-order' && $route.query.event_id === '3382'">
            <img :src="avalonLogoWhite" alt="Avalon logo" class="logo">
          </template>
          <template v-else>
            <img v-if="navTextColor.value === '#000'" :src="logoBlack" class="logo" alt="RegToEvent Logo">
            <img v-else :src="logoWhite" class="logo" alt="RegToEvent Logo">
          </template>
        </router-link>
      </div>

      <div class="navbar__bot__element langBar p-0" :class="{'stream': isStream}">
        <LangBar />
        <span class="navbar__info-text stream-logout text-truncate text-right" v-if="isStream">
          <a href="#" @click.prevent="handleLogout">
            <span class="pr-2">{{$t('menu.logout')}}</span>
            <i class="fa fa-sign-out"/>
          </a>
        </span>
      </div>

<!--      <div class="navbar__bot__element balance">-->
<!--        <div v-if="isLoggedIn">-->
<!--          Баланс 0.00 $-->
<!--          <i class="fa fa-money"/>-->
<!--        </div>-->
<!--      </div>-->
      </div>
    </div>
    <div class="navbar__info" v-if="isLoggedIn && !short">
      <div class="container">
        <span class="navbar__info-text text-left">
          <i class="fa fa-user-o mr-1"/>
          {{ getUserEmail }}
        </span>
<!--        <span class="navbar__info-text text-center">-->
<!--          <i class="fa fa-money mr-1"/>-->
<!--          {{$t('menu.balance')}}-->
<!--           0.00 $-->
<!--        </span>-->
        <span class="navbar__info-text text-right">
          <a href="#"  v-on:click.prevent="handleLogout">
            {{$t('menu.logout')}}
            <i class="fa fa-sign-out"/>
          </a>
        </span>
      </div>
    </div>
    <div class="navbar__info--mobile" v-if="isLoggedIn && !short">
      <div class="container">
        <b-dropdown>
          <template #button-content>
            <i class="fa fa-user-o mr-1"/>
            {{ getUserEmail }}
          </template>
          <b-dropdown-text>{{$t('menu.balance')}} 0.00 $</b-dropdown-text>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item v-on:click.prevent="handleLogout">Выйти</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import {logout} from "@/services/customer/auth/auth";
import LangBar from "@/components/general/LangBar";
import logoWhite from '@/assets/logo.png'
import logoBlack from '@/assets/img/logo-black.png'
import avalonLogo from '@/assets/avalon-logo.png'
import avalonLogoWhite from '@/assets/avalon-white.png'

export default {
  name: "NavBar",
  props: ['short', 'isStream'],
  data () {
    return {
      logoWhite,
      logoBlack,
      avalonLogo,
      avalonLogoWhite,
      displayBottom: false,
      navColor: {
        id: 1,
        value: ''
      },
      navTextColor: {
        id: 1,
        value: ''
      }
    }
  },

  components: {
    LangBar,
  },

  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    },
    getUserEmail() {
      return this.$store.getters.getUserEmail
    },
    route() {
      return this.$route.name
    }
  },
  created() {
    this.$bus.$on('changeFormColor', (data) => this.navColor = data)
    this.$bus.$on('changeTextColor', (data) => this.navTextColor = data)
  },
  methods: {
    checkLogo() {
      return this.$route.path === 'add-order'
    },
    handleLogout (event) {
      event.preventDefault()
      logout().then(() => {
        this.$router.push('/login')
      }).catch(() => {

      })
    },
    gotToLogin () {
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss">
$primary-bg: rgba(83,59,170,.9);
$primary: #533BAA;
//.flat-bg {
//  position: relative;
//  &:after {
//    content: '';
//    position: absolute;
//    top: 100%;
//    left: 0;
//    background: #000;
//    width: 100%;
//    height: 400px;
//    z-index: -1;
//  }
//}

.navbar__bot, .navbar__info {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.langBar {
  text-align: right;
  padding-right: 10px;
}

.navbar {
  width: 100%;
  height: 95px;
  /* position: absolute; */
  color: #fff;
  z-index: 10;
  display: flex;
  flex-direction: column;
  /*margin-bottom: 20px;*/
  padding: 0 !important;
  text-align: center;
  background: $primary-bg;
  //-webkit-box-shadow: 0 4px 6px -6px black;
  //-moz-box-shadow: 0 4px 6px -6px black;
  //box-shadow: 0 4px 6px -6px black;
  //background: linear-gradient(162deg, rgba(2,0,36,0.85) 0%, rgba(83,59,170,0.85) 0%, rgba(45,30,30,0.9) 100%);
}

.navbar__bot {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  line-height: 55px;
}

.navbar__info,.navbar__info--mobile {
  display: flex;
  background: #fff;
  height: 40px;
  width: 100%;
  box-shadow: 0 4px 20px rgba(0,0,0,.04)
}

.navbar__info--mobile {
  display: none;
  .dropdown {
    width: 100%;
    height: 100%;
    .dropdown-toggle {
      border: 0;
      color: #000 !important;
      font-size: 14px;
      background: #fff;
      &:hover, &:focus, &:active {
        background: transparent;
      }
      &::after {
        content: '';
        display: inline-block;
        font-size: 14px;
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
      }
    }
    .dropdown-menu {
      right: 0;
      margin: 0px -10px;
      padding: 1rem 10px;
      font-size: 14px;
      .b-dropdown-text {
        padding: 0 1.2rem;
      }
    }
  }
}

.navbar__info-text {
  color: #000;
  width: 33%;
  a[href] {
    color: inherit;
    &:hover {
      text-decoration: none;
      color: $primary;
    }
  }
}

.navbar__bot__element {
  width: 20%;
  color: inherit;
  height: 55px;
  .logo {
    padding: 7px 0;
  }
  &.stream {
    width: 33%;
    display: flex;
    align-items: center;
    .position-relative {
      flex-grow: 1;
    }
    .navbar__info-text {
      width: auto;
      margin-left: 20px;
      color: #fff;
      white-space: nowrap;
      a:hover {
        color: #fff;
        opacity: 0.5;
      }
    }
  }
}

//.btn {
//  color: #fff!important;
//}

.cabinet {
  text-align: left;
  padding-left: 10px;
}

.cabinet a{
  text-decoration: none;
  color: inherit;
}

.logo {
  object-fit: contain;
  width: 100%;
  height: 100%
}

@media screen and (max-width: 880px) {
  .navbar__info {
    display: none;
  }
  .navbar__info--mobile {
    display: flex;
  }
  .navbar__bot {
    justify-content: center!important;
  }
  .email {
    display: none
  }
  .balance {
    display: none
  }
  .cabinet-text {
    display: none;
  }

  @media screen and (max-width: 550px) {
    .navbar__bot__element.langBar {
      .dropdown-menu {
        left: -200% !important;
      }
    }
    .stream-email {
      a {
        font-size: 14px;
      }
    }
    .stream-logout {
      span {
        display: none;
      }
    }
  }
}
</style>