import store from '@/store';
import {authUser} from '@/api/customer/auth/auth'

export const login = async (meta) => {
  let authData = {}
  try {
    authData = await authUser(meta)
  } catch (error) {
    throw new Error(error.response.data.message)
  }

  if (!authData.data.access_token) {
    throw new Error('No token found')
  }
  const loginMeta = {
    token: authData.data.access_token,
    email: meta.email,
    first_name: authData.data.first_name,
    last_name: authData.data.last_name,
    telephone_full_number: authData.data.telephone_code + authData.data.telephone_number,
    role: authData.data.role.role
  }

  await store.dispatch('login', loginMeta)
}

export const logout = async () => {
  await store.dispatch('logout')
}

export const isAuthenticated = async () => {
  return store.getters.isLoggedIn
}

export const getUserEmail = async () => {
  return store.getters.getUserEmail
}